.orders-page-container {
    display: flex;
    width: 100%;
    padding: 20px;
  }
  
  .sidebar {
    width: 25%;
    padding: 20px;
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
  }
  
  .profile-info h4 {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .profile-info p {
    margin: 5px 0;
    color: gray;
  }
  
  .profile-info a {
    color: #f50057;
    text-decoration: none;
  }
  
  /* .menu {
    margin-top: 30px;
  } */
  
  .menu h5 {
    margin: 15px 0;
    color: gray;
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu ul li {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .main-content {
    width: 75%;
    padding: 20px;
  }
  
  .tabs {
    display: flex;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px 20px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .tabs .active {
    border-bottom: 3px solid #3f51b5;
    color: #3f51b5;
  }
  
  .orders-content {
    text-align: center;
  }
  
  .no-orders {
    padding: 40px;
  }
  
  .no-orders h3 {
    margin: 20px 0;
  }
  
  .continue-shopping-btn {
    background: linear-gradient(90deg, #f50057 30%, #651fff 90%);
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .continue-shopping-btn:hover {
    background: linear-gradient(90deg, #f50057 10%, #651fff 70%);
  }
  