.checkout-header{
    background: linear-gradient(180deg, #FFFFFF 0%, #DCDCDC 100%);
}
.checkout-btn{
    /* background: #111579; */
}
.checkout-text{
    color: #2E2E2E;
    font-family: 'Inter';
}
.product-card{
    background: #D9D9D9;
    border-radius: 15px;

    .product-img{
        border: 1px solid #000000;
        border-radius: 15px;
        /* width: 25%;
        height: 35%; */
    }
    .product-price{
        color: #3523A3;
        font-size: 90%;
    }
    .product-details{
        color: #3523A3;
        font-size: 70%;
    }
}
.product-card-order{
    background: #FFFFFF;
    border-radius: 15px;

    .product-img{
        border: 1px solid #000000;
        border-radius: 15px;
        /* width: 25%;
        height: 35%; */
    }
    .product-price{
        color: #3523A3;
        font-size: 90%;
    }
    .product-details{
        color: #3523A3;
        font-size: 70%;
    }
}
.order-summary{
    background: #D9D9D9;
    border-radius: 15px 15px 0px 0px;
    .order-price{
        color: #201180;
        font-size: 75%;
        font-weight: 600;
    }
}
.grand-total{
    background: #D9D9D9;
    border-radius: 0px 0px 15px 15px;
        color: #201180;
        font-size: 90%;
        font-weight: 600;
    }
.view-order-summary{
    /* background: #AECFFF; */
    .price{
        color: #172B85;
        font-size: 100%;
        font-weight: 700;
    }
    .place-order{
        /* background: var(--The-Blue, #111579); */
        border-radius: 10px;
    }
}
.confirmation-model{
    background: #FFFFFF;
    border-radius: 20px;
    .header{
        background: #DFDFDF;
        border-radius: 20px 20px 0px 0px;
        color: #070940;
        font-weight: 700;
    }
    .description{
        color: #000000;
        font-weight: 500;
    }
    .details{
        /* background: #44467A; */
        border-radius: 10px;
        color: #E4E4E4;
    }
    .conform-btn{
        border-radius: 12px;
    }
    .edit-btn{
        border: 1px solid #000000;
        border-radius: 12px;
    }
}
.order-summary-text{
    color: #3523A3;    ;
}
.divider-order-summary{
  width: 3px; /* Adjust the width as needed */
  background-color: #C0C4E5; /* Choose your line color */
  height: 97%; /* Make the line span the entire height */
  position: absolute; /* Use absolute positioning for better control */
  left: 50%; /* Place the line in the middle */
  transform: translateX(-50%); /* Center the line horizontally */
}
.divider-horizontal-order-summary{
    border: 1px solid #232323
}