.price-breakup-container {
  /* width: 400px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 3%;
  height: 75px;
}

.header .title {
  color: #404040;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}

.price-breakup h3 {
  font-size: 16px;
  color: #444;
  margin-bottom: 10px;
}

.price-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 5px 0;
}

.price-row span {
  font-size: 12px;
  color: #000000;
  font-weight: 700;
}
.price-row{
  .details{
    color: #636363;
    font-size: 12.5px;
    text-align: center;
  }
  .detailsHeading{
    color: #636363;
    font-size: 12.5px;   
  }
}

.price-row.total {
  font-weight: bold;
  text-align: center;
}
.price-row.totalHeading {
  font-weight: bold; 
}

.hr-divider{
  margin: 3% 0% 3% 0%;
  /* padding: -15px 0%; */
}