@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Serif+Display&family=Italiana&family=Lora&family=Montserrat&family=Open+Sans&family=Roboto+Mono&display=swap');
body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  margin-top: 100px;
  padding: 0;
}
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    margin-top: 55px;
    padding: 0;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&display=swap');

html {
  overflow-x: hidden;
}

.font-dm-serif-display {
  font-family: 'Dm Serif Display', Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h2.head_title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  font-family: 'DM Serif Display', serif;
}

p.heading_text {
  color: #12161b;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.padding_150 {
  padding: 9.375rem 0px;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  right: -107px;
  z-index: 996;
  background: #21bf73;
  width: 60px;
  height: 60px;
  border-radius: 38px;
  transition: all 0.4s;
  top: 57px;
}

.back-to-top i {
  font-size: 25px;
  color: #404042;
  line-height: 0;
}

.back-to-top:hover {
  background: transparent;
  border: 1px solid #21bf73;
  color: #fff;
}

div#back {
  position: relative;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*---------------
# Header
-----------------*/
.header_top {
  height: 25px;
  background: #3044f4;
  width: 100%;
}

#header {
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid #b0b0b0;
  background: #fff;
  top: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
}

#header.header-scrolled {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  z-index: 997;
  position: fixed;
  top: 0;
  width: 100%;
}

.header_container {
  width: 1440px;
  padding: 0px 15px 0px 15px;
  margin: auto;
  /* gap: 45px; */
}

.nav_rightside_text {
  display: flex;
  /* gap: 67px; */
  align-items: center;
}

.nav_rightside_text form {
  width: 300px;
  position: relative;
}

.nav_rightside_text form input {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #3044f4;
  background: #f5f5f5;
  outline: none;
  font-size: 10px;
  line-height: 12.1px;
  font-weight: 600;
  color: #afafaf;
  height: 50px;
  max-height: 50px;
}

.nav_rightside_text form input::placeholder {
  font-size: 10px;
  line-height: 12.1px;
  font-weight: 600;
  color: #afafaf;
}

.nav_rightside_text form .search {
  position: absolute;
  right: 0;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  outline: 0;
  border: 0;
  background: #3044f4;
  padding: 14px 13px 12px 14px;
}

/*----------------------------
#Desktop Navigation 
-----------------------------*/
.navbar {
  position: relative;
}

.navbar ul {
  display: flex;
  align-items: center;
}

.navbar ul .dropdown {
  /* position: relative; */
  display: flex;
  justify-content: center;
}

.navbar ul {
  display: flex;
  gap: 21px;
}

.navbar ul li a {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #2f326f;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.navbar ul li {
  color: #09090b;
}

.navbar ul li:hover {
  color: #21bf73;
}

.navbar ul li a:hover .navbar ul li a i {
  transform: rotate(180deg);
}

.nav_rightside_text #nav_mobileshow {
  display: none;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  /* top: 53px; */
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  width: 303px;
  background: #161616;
  /* border-radius: 16px; */
  margin: auto;
  padding: 30px 42px 14px 43px;
  top: 52px;
  right: -300px;
  left: -300px;
}

.navbar .dropdown ul li a {
  /* padding: 8px 0px;/ */
  margin: 0;
  color: #fff;
  position: relative;
  transition: 0.5s ease-in-out;
  display: block;
  width: fit-content;
  text-transform: capitalize;
  cursor: pointer;
}

/* .navbar .dropdown ul li a::after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 6px;
    height: 2px;
    background-color: #21BF73;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: all .4s;
    -webkit-transform-origin: left;
    transform-origin: left;
} */

.navbar .dropdown ul li a:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  color: #fff;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.dropdown:hover > a > i {
  transform: rotate(180deg);
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  display: none;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 9998;
  font-size: 30px;
  line-height: 0;
  cursor: pointer;
  color: #fff;
}

@media (max-width: 1024px) {
  .mobile-nav-toggle {
    display: block;
    color: #09090b;
  }

  #navbar {
    position: fixed;
    left: -130%;
    top: 86px;
    z-index: 9999;
    overflow-y: auto;
    background: #161616;
    backdrop-filter: blur(10px);
    padding: 21px 40px 30px !important;
    border-radius: 16px;
    width: 384px;
  }

  #navbar.navbar-mobile {
    left: 0;
    transition: 0.3s ease-in;
    display: unset;
    width: 100%;
    top: 109px;
    border-radius: 0;
    height: 100%;
  }

  .navbar ul {
    display: none;
  }

  .navbar ul li a {
    margin-bottom: 16px;
    color: #fff;
  }

  .navbar ul li:last-child a {
    margin-bottom: 0px;
  }

  .navbar ul .dropdown {
    display: block;
    position: unset;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  div#nav_desktop {
    /* display: none; */
  }

  #navbar {
    padding: 30px 12px !important;
    justify-content: center;
  }

  #navbar.navbar-mobile {
    right: 0;
    transition: 0.3s ease-in;
    display: unset;
    height: 319px;
    width: 100%;
  }

  .header-scrolled #navbar.navbar-mobile {
    top: 80px;
  }

  .nav_rightside_text #nav_mobileshow {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .navbar_btn {
    width: 100%;
  }

  .nav_rightside_text .log-btn,
  select#language {
    color: #fff;
  }

  .nav_rightside_text .sign-btn {
    background: transparent;
    border-radius: 24px;
  }

  .nav_rightside_text a {
    /* display: block; */
    text-align: center;
  }

  #navbar {
    display: unset;
    height: 700px !important;
  }

  .nav_rightside_text a.sign-btn {
    border: 1px solid #8e8d8d;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
    border-radius: 9999px;

    padding: 5px 26px;
  }

  .custom-select-trigger {
    color: #fff;
  }

  .custom-select-trigger:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  .custom-options {
    position: relative;
  }
}

.navbar-mobile ul {
  display: block;
  margin-top: 40px;
}

.navbar-mobile .dropdown ul,
.navbar-mobile .dropdown .dropdown ul {
  position: unset;
  top: 0rem;
  left: 15rem;
  padding: 10px 0 20px;
  display: none;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: transparent;
  transition: all 0.5s;
  width: 266px;
}

.navbar-mobile .dropdown ul li,
.navbar-mobile .dropdown .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a,
.navbar-mobile .dropdown .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i,
.navbar-mobile .dropdown .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown > .dropdown-active,
.navbar-mobile .dropdown .dropdown > .dropdown-active {
  display: block;
  padding-left: 50px;
  margin: unset;
}

.navbar-mobile .dropdown > .header-scrolled .mobile-nav-toggle {
  color: #09090b;
}

.header-scrolled .mobile-nav-toggle.bi-x {
  color: #09090b;
}

/* Footer */
/* .footer {
    background: #0A0D4F;
    padding: 55px 0px 20px 0px;
}

.footer_container {
    width: 1440px;
    padding: 0px 74px 0px 79px;
    margin: auto;
}

.footer_main {
    display: flex;
    gap: 201px;
}

.ftr_logo {
    max-width: 360px;
}

.ftr_logo p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #FFF;
    font-family: "Open Sans", sans-serif;
    margin-top: 30px;
}


.ftr_links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-area: 84px;
    padding-top: 5px;
}

.ftr_link h4 {
    font-family: "Open Sans", sans-serif;
    color: #FFF;
    font-size: 15px;
    font-weight: 800;
    line-height: 20.43px;
    margin-bottom: 15px;
}

.ftr_link ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ftr_link ul li a p {
    font-family: "Open Sans", sans-serif;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.43px;
}

.ftr_link p {
    font-family: "Open Sans", sans-serif;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.43px;
}

.ftr_link .social_link {
    margin-top: 30px;
}

.ftr_link .social_link .soc_link {
    display: flex;
    gap: 10px;
}

.soc_link a {
    border: 1px solid #222AE7;
    border-radius: 100%;
    width: 38px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
}

.soc_link a i {
    color: #FFF;
}

.ftr_btm {
    text-align: center;
    margin-top: 35px;
}

.ftr_btm .payment_option {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 24.5px;
}

.copyright p {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFF;
    font-family: "Open Sans", sans-serif;

}

#footer-for-mobile {
    display: none;
}

.download {
    padding: 32px 19px 28px;
    border: 1px solid #191F94;
    background: #101362;
    border-radius: 25px;
    margin-bottom: 24px;
}

.download h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #2D74FF;
}

.download p {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 11px;
} */

/* Footer */
.rounded-full {
  border-radius: 100%;
}

/* Ḥome Banner */
.custom-container {
  width: 1440px;
  padding: 0 14px;
  margin: auto;
}

.home_banner {
  /* background: url('assets/img/hero-section-main.png'); */
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 266px 0 0 0;
}

.banner-content {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #ffffff 80%);
  text-align: center;
  padding-bottom: 19px;
}

.banner_title h1 {
  font-family: 'DM Serif Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 120px;
  line-height: 165px;
  color: #23309f;
  font-family: 'DM Serif Display', serif;
}

.banner_title h1 .and {
  font-family: 'DM Serif Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 123px;
  color: #2f326f;
}

.second {
  position: relative;
  margin-top: -72px;
  margin-left: 24rem;
  display: block;
}

/* Ḥome Banner */

.Categories_sec {
  padding: 19px 0 55px 0;
}

.Categories_sec .custom-container {
  padding: 0 38px;
}

.catogory_card {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.catogory_card p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: center;
}

.Categories_card {
  margin-top: 38px;
}

/* Swiper */
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: white;
  opacity: 1;
  margin-inline: 1.5px;
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: 13px;
  height: 13px;
  background-color: #d9d9d9;
}

/*TEMP */
.--swiper-slide-custom-collection {
  height: 435px !important;
  width: 345px !important;
}

@media (min-width: 424px) and (max-width: 430px) {
  .--swiper-slide-custom-collection {
    height: 360px !important;
    width: 275px !important;
  }
}
@media (min-width: 1024px) {
  /* Adjust based on Tailwind's breakpoints */
  .--swiper-slide-custom-collection {
    height: 435px !important;
    /* Default height for slides */
    width: 345px !important;
    /* Default width for slides */
  }
}
@media (max-width: 991px) {
  .swiper-pagination {
    margin-bottom: 10px;
  }
}
.help-section-main{
  position: fixed;
  bottom: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background: #1E1E1ED9;
  z-index: 100;
}
.help-section{
  display: block;
  position: fixed;
  bottom: 2%;
  right: 0%;
  z-index: 1001;
  cursor: pointer;
  width: 245px;
}

.help-section-description{
  gap: 12px;
  border-radius: 12px;
  position: absolute;
  width: 100%;
  right: 4%;
  bottom: calc(100% + 28px);
  display: flex;
  flex-direction: column;
  background: transparent;
  transition: left 2s;
  .talk-to-experts{
    width: 100%;
    height: 46px;
    border-radius: 12px;
    background: rgb(255, 255, 255);
    padding: 13px 10px;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #A31C7D;
    line-height: 16.94px;
    font-family: InterSemiBold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    transition: transform 0.25s;
    transform: translateX(0px);
  }
  .live-video-call{
    width: 100%;
    height: 46px;
    border-radius: 12px;
    background: rgb(255, 255, 255);
    padding: 13px 10px;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3B115B;
    line-height: 16.94px;
    font-family: InterSemiBold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    transition: transform 0.25s;
    transform: translateX(0px);
  }
  .chat-on-whatsapp{
    width: 100%;
    height: 46px;
    border-radius: 12px;
    background: rgb(255, 255, 255);
    padding: 13px 10px;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #15853F;
    line-height: 16.94px;
    font-family: InterSemiBold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    transition: transform 0.25s;
    transform: translateX(0px);
  }
  .book-try-at-home{
    width: 100%;
    height: 46px;
    border-radius: 12px;
    background: rgb(255, 255, 255);
    padding: 13px 10px;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #973CDE;
    line-height: 16.94px;
    font-family: InterSemiBold, "Helvetica Neue", Helvetica, Arial, sans-serif;
    transition: transform 0.25s;
    transform: translateX(0px);
  }
}
/* Add this CSS to your styles file (e.g., App.css or index.css) */
.full-header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures it stays on top of other elements */
  width: 100%;
}

.full-header > * {
  display: block;
}

.full-header .marquee-container {
  background-color: #3044F4; /* Adjust as needed */
  color: white;
  height: 25px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
