@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* video section start */
.video-section--wrapper {
  height: 586px;
}

.video-section--wrapper .video-section-maintitle {
  display: block;
  margin-left: -40px;
}

.video-section--wrapper .video-section-maintitle {
  font-size: 120px;
  font-weight: 400;
}

.video-section--wrapper .video-section-maintitle span {
  font-size: 100px;
  font-weight: 400;
}

/* video section Ends */

/* shop by category section start */
.category-content--wrapper {
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
}

.category-content--wrapper .category-content {
  width: 12.5%;
  box-sizing: border-box;
}

.category-content--wrapper .category-content img {
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.section-title {
  font-size: 30px;
  font-family: playFair display;
  margin-bottom: 20px;
}

/* shop by category section Ends */

/* swiper section start */
.swiper-pagination-bullets-dynamic {
  bottom: 15% !important;
}

.swiper-section--wrapper {
  max-width: 100% !important;
}

/* swiper section Ends */

/* metal section start */
.section-subtitle {
  font-size: 30px;
}

.section-maintitle {
  font-family: playFair display;
  font-size: 60px;
  line-height: 90%;
}

.metaltype-section-content {
  width: 80%;
  margin: auto;
}

.metaltype-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  row-gap: 20px;
}

.metaltype-content .metal-type-category-image {
  width: 48%;
  height: auto;
}

.metaltype-content .category-desc {
  font-size: 12px;
  font-weight: 600;
}

/* metal section Ends */
.budget-section-content {
  /* width: 90%; */
  gap: 23px;
}

@media (max-width: 576px) {
  .video-section-maintitle {
  }

  .category-content--wrapper {
    flex-wrap: wrap;
  }

  .category-content--wrapper .category-content {
    width: 25%;
  }

  .category-content--wrapper .category-content .category-name {
    font-size: 12px;
    margin: 0;
  }

  .section-title {
    font-size: 20px;
    padding: 10px 0;
  }

  /*  */
  .section-subtitle {
    font-size: 12px;
    text-align: center;
  }

  .section-maintitle {
    font-size: 30px;
    text-align: center;
    /* line-height: 160%; */
  }

  .metaltype-section-content {
    width: 100%;
    margin: auto;
  }

  .metaltype-content {
  }

  .metaltype-content .metal-type-category-image {
    width: 48%;
    height: auto;
  }

  .metaltype-content .category-desc {
    font-size: 12px;
    font-weight: 600;
  }

  .budget-section-content {
    padding: 0 20px;
    width: 100%;
    display: block;
    padding-top: 20px;
    gap: 0;
  }

  .budget-section-content .budget-1 {
  }

  .budget-section-content .budget-2 {
    margin-left: auto;
    margin-top: -70px;
  }

  .budget-section-content .budget-3 {
    margin-top: -30px;
  }

  .budget-section-content .budget-4 {
    margin-left: auto;
    margin-top: -15px;
  }

  .video-section--wrapper {
    height: 270px;
  }

  .video-section--wrapper .video-section-maintitle {
    font-size: 40px;
    font-weight: 600;
  }

  .video-section--wrapper .video-section-maintitle span {
    font-size: 35px;
    font-weight: 600;
  }
}

@media (max-width: 400px) {
  .budget-section-content .budget-1 {
  }

  .budget-section-content .budget-2 {
    margin-left: auto;
    margin-top: -20px;
  }

  .budget-section-content .budget-3 {
    margin-top: -10px;
  }

  .budget-section-content .budget-4 {
    margin-left: auto;
    margin-top: 0;
  }
}

/* // last 3 section */
.product-card--wrapper {
  margin: 0px;
  padding: 0px;
  background-color: white;
  border: 1px solid #00000026;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  width: 250px;
}

@media (min-width: 420px) {
  .product-card--wrapper {
    width: 210px;
    height: 300px;
  }
}

.image-container {
  height: 210px;
  width: 210px;
}
.product-card--wrapper .floating-tags {
  position: absolute; 
  z-index: 10; /* Ensure it has a higher z-index than the Swiper content */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: to ensure text is readable */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.product-card--wrapper .floating-tags.likebtn {
  top: 11px;
  right: 14px;
}

.product-card--wrapper .floating-tags.karattag {
  bottom: 0;
  right: 0;
  font-size: 10px;
  font-weight: bold;
  background-color: #ffd53f;
  box-shadow: inset 0 0 0 2px #00000026;
  color: black;
  padding: 5px 15px;
  border-radius: 12px 0 0 0;
  font-family: Montserrat;
  z-index: 1;
}

.product-card--wrapper .floating-tags.periodtag {
  top: 0;
  left: 0;
  font-size: 10px;
  font-weight: bold;
  background-color: #169dff;
  box-shadow: inset 0 0 0 2px #00000026;
  color: white;
  padding: 5px 15px;
  border-radius: 0 0 12px 0;
  font-family: inter;
  z-index: 1;
}

.product-card--wrapper .product-detail-section .product-name {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card--wrapper .product-detail-section .p-price {
  font-family: 'Inter', sans-serif;
  color: #404040;
}

.product-card--wrapper .product-detail-section .share-icons {
  display: flex;
  gap: 4px;
  /* Added for better spacing */
}

.product-card--wrapper .product-detail-section .share-icons .icons {
  background-color: #2bff66;
  /* Default background color */
  overflow: hidden;
  border-radius: 12px;
}

.product-card--wrapper
  .product-detail-section
  .share-icons
  .icons:nth-child(1) {
  background-color: #2bff66;
}

.product-card--wrapper
  .product-detail-section
  .share-icons
  .icons:nth-child(2) {
  background-color: #e3e4ff;
}

@media (max-width: 576px) {
  .product-card--wrapper {
    width: 100%;
    padding-left:1px;
    padding-right:3px;
  }

  .product-card--wrapper .floating-tags.periodtag {
    font-size: 10px;
    padding: 4px 12px;
  }

  .product-card--wrapper .floating-tags.karattag {
    padding: 4px 12px;
  }
}

@media (max-width: 380px) {
  .product-card--wrapper .product-image-section {
    height: 150px;
  }

  .product-card--wrapper .product-detail-section {
    padding: 5px;
  }

  .product-card--wrapper .product-detail-section .share-icons .icons {
    height: 30px;
    width: 30px;
  }
}

/* ============================================ */

/* ============================================ */
.collection-section--wrapper {
  padding: 84px 20px;
  background-color: #84abf8;
}

.collection-section--wrapper .collection-content {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.collection-section--wrapper .title-desc-section {
  width: 30%;
}

.collection-section--wrapper .title-desc-section h4.collection-subtitle {
  font-family: 'playFair', serif;
  font-size: 30px;
  font-weight: 900;
  color: white;
}

.collection-section--wrapper .title-desc-section h1.collection-maintitle {
  font-family: 'playFair', serif;
  font-size: 65px;
  font-weight: 500;
  line-height: 0.6;
  color: white;
}

.collection-section--wrapper .title-desc-section p.collection-desc {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  width: 90%;
  margin-top: 16px;
  color: white;
}

.collection-section--wrapper .image-swiper-section {
  width: 70%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.collection-section--wrapper .image-swiper-section .collection--wrapper {
  width: 95%;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
}

.collection-section--wrapper .image-swiper-section .collection--wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.collection-section--wrapper .image-swiper-section .collection--wrapper p {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  padding: 14px 0;
  background-color: #d9d9d9;
  color: #0e1150;
}

@media (max-width: 576px) {
  .collection-section--wrapper .collection-content {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .collection-section--wrapper .title-desc-section {
    width: 100%;
    text-align: center;
  }

  .collection-section--wrapper .title-desc-section p.collection-desc {
    font-size: 16px;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 20px;
  }

  .collection-section--wrapper .title-desc-section h4.collection-subtitle {
    font-size: 14px;
  }

  .collection-section--wrapper .title-desc-section h1.collection-maintitle {
    font-size: 30px;
    line-height: 0.6;
  }

  .collection-section--wrapper .image-swiper-section {
    width: 100%;
  }

  .collection-section--wrapper .image-swiper-section .collection--wrapper {
    width: 97%;
    margin: 0 auto;
  }

  .collection-section--wrapper {
    padding: 20px 0;
  }
}

.video-container video {
  object-fit: cover;
}

.navbar-item {
  color: black !important;
}
