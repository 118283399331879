/* product details page css */
.product-details-background {
  background-color: #e4e4e4;
}
.product-text {
  font-family: 'Inter';
  color: #2f2f2f;
}
.product-title {
  font-size: 125%;
  font-weight: 600;
}
.product-price {
  font-size: 187.5%;
  font-weight: 700;
}
.product-price-des {
  font-size: 10px;
  font-weight: 400;
  color: #696969 !important;
}
.product-offer {
  font-size: 81.25%;
  font-weight: 400;
  color: #000000 !important;
}
.product-des {
  font-size: 112.5%;
  font-weight: 700;
  color: #0a1434;
}
.product-des-1 {
  font-size: 10px;
  font-weight: 700;
  color: #4f4f4f;
}
.product-code {
  font-size: 75%;
  font-weight: 500;
  color: #181eab;
}
.btn-price-breakup {
  background: #6da5e8;
  border-radius: 10px;
  width: 130px;
  height: 30px;
  border-radius: 10px;
  font-weight: 700;
  /* font-size: 25%; */
  color: #000000;
}
@media (max-width: 1024px) {
  .btn-price-breakup {
    background: #6da5e8;
    border-radius: 62.5%;
    width: 130px;
    height: 30px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 13px;
    color: #000000;
  }
}
.product-des-2 {
  padding: 10px;
  border: 1px solid #b4b0b0;
  border-radius: 10px;
  position: relative;
}
.product-desc-3 {
  font-size: 90%;
  font-weight: 700;
  color: #000000;
}
@media (max-width: 1024px) {
  .product-desc-3 {
    font-size: 77%;
    font-weight: 700;
    color: #000000;
  }
}
.product-desc-img {
  height: 32px;
  width: 36px;
}
.divider {
  width: 1px; /* Adjust the width as needed */
  background-color: #a0a0a0; /* Choose your line color */
  height: 100%; /* Make the line span the entire height */
  position: absolute; /* Use absolute positioning for better control */
  left: 50%; /* Place the line in the middle */
  transform: translateX(-50%); /* Center the line horizontally */
}

.product-desc-4 {
  /* border: 2px solid #2087ff; */
  border-radius: 17px;
}

.product-customize {
  /* background: #2086ff; */
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 0px 15px 15px 0px;
  opacity: 1px;
  padding: 1%;
}
.add-to-cart {
  /* background: #1727b5; */
}
@media (max-width: 1024px) {
  .add-to-cart {
    /* background: #3278ff; */
  }
}
.product-delivery {
  font-size: 18px;
  font-weight: 600;
}
.product-delivery-date {
  border: 1px solid #00000033;
}
.product-delivery-date-desc {
  color: #172b85;
  font-size: 88%;
  font-weight: 600;
  text-align: left;
}
.product-contact {
  color: #4f4f4f;
  font-size: 10px;
  font-weight: 400;
  gap: 0px;
  opacity: 0px;
  .number {
    font-size: 10px;
    font-weight: 600;
    line-height: 12.1px;
  }
}
.warranty {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 1024px) {
  .warranty {
    font-family: Open Sans;
    font-size: 9px;
    font-weight: 700;
    text-align: center;
  }
}
.store {
  color: #421e5f;
  font-family: Open Sans;
  font-weight: 700;
}
.store-description {
  color: #404040;
  font-family: Open Sans;
  font-weight: 400;
}
.store-btn {
  border: 1px solid #1e509b30;
  color: #222ae7;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: #d1e6ff;
}
.video-btn {
  border: 1px solid #209b1e30;
  color: #1e881c;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: #e3ffd1;
}
/* For large screens (min-width: 1024px) and above */
/* media query */
@media (min-width: 1024px) {
  .product-details-padding {
    padding: 2% 6% 0.5% 6%; 
  }
}
@media (max-width: 1000px) {
  .product-customize {
    /* background: #2086ff; */
    width: 100%;
    /* height: 45px; */
    border-radius: 0px 0px 15px 15px;
    opacity: 1px;
  }
}
/*  */
/* Target the 4th slide in the specific Swiper with class 'custom-swiper' */
.custom-swiper .swiper-slide:nth-of-type(5) {
  width: 2vw !important;
}
@media (max-width: 768px) { /* Mobile view */
  .custom-swiper .swiper-slide:nth-of-type(5) {
    width: 8vw !important; /* Adjust for smaller screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) { /* Tablet view */
  .custom-swiper .swiper-slide:nth-of-type(5) {
    width: 10vw !important; /* Adjust for tablet screens */
  }
}
.product-path{
  color: #585858;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 12.19px;
  letter-spacing: 0.1em;
  text-align: left;  
}