.text-color{
    color: rgb(239 68 68);
}

.store-timing-container {
    /* display: flex; */
    /* flex-direction: column; */
    /* border: 1px solid #E5E5E5; */
    width: 100%;
    background-color: #FAF3E3;
    justify-content: space-between;
    /* text-align: center; */
    align-items: center;
    height: 11%;
  }
  .store-timing-container-mobile {
    /* display: flex; */
    /* flex-direction: column; */
    /* border: 1px solid #E5E5E5; */
    width: 100%;
    background-color: #FAF3E3;
    /* justify-content: space-between; */
    /* text-align: center; */
    /* align-items: center; */
    /* height: 100%; */
  }
  .store-timing-header {
    display: flex;
    align-items: center;
    padding: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #9C866D;
    width: 25%;
  }

  .store-timing-mobile {
    display: flex;
    align-items: center;
    padding: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #9C866D;
    /* width: 50%; */
  }
  
.icon-calendar {
  margin-right: 10px;
  width: 3rem;
  height: 3rem;
  background: url('../../../public/assets/img/calendar.svg') no-repeat;
  background-size: contain;
  filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(85%);
}
  
  .day-time {
    padding: 8px 10px;
  }
  
  .closed {
    background-color: rgb(239 68 68);
    color: #fff;
    font-weight: bold;
    /* height: 100%; */
    /* padding: 0; Remove any padding */
    /* margin: 0; */
  }
  
  .day {
    font-size: 14px;
  }
  
  .time {
    font-size: 14px;
  }
  