.customization-container {
  padding: 20px;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.section {
  margin-bottom: 5%;
}

.options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 2%;
}

.option {
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  opacity: 1px;
  /* background: #c2deff; */
}

.option.selected {
  /* background: #6da5e8; */
}

.option p {
  font-family: Inter;
  font-size: 100%;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
  color: #000000;
}
.option .size {
  font-family: Inter;
  font-size: 70%;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
  color: #000000;
}
.stock {
  width: 100%;
  margin-top: 5%;
  gap: 0px;
  border-radius: 8px;
  opacity: 1px;
  background: #ffffff;
  padding: 2.5%;
  color: #363636;
  font-size: 80%;
  font-family: Inter;
  font-weight: 600;
  line-height: 14.52px;
  text-align: center;
}

.size-options .option {
  width: 31%;
}

.guide-link {
  color: #007bff;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}

.confirm-button {
  /* background-color: #007bff; */
  color: white;
  padding: 15px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.fixed-button-container {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  right: 0%;
  width: 100%;
  background-color: white; /* Optional: Background to separate the button from the content */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  z-index: 1000;
}

.confirm-button:hover {
  background-color: #2086ff;
}

.price-section {
  text-align: left;
  margin: 5%;
}
.price-section .title {
  color: #404040;
  font-family: Inter;
  font-size: 75%;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
}
.price-section .price {
  color: #282828;
  font-family: Inter;
  font-size: 125%;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
}

.header {
  /* background: #d9e6f1; */
}
.header .close {
  margin-right: 6%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 3%;
  height: 75px;
}
.header .title {
  color: #404040;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
}
.header .price{
  font-family: Inter;
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
}
.header .price2{
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
}

.metal-text {
  color: #000000;
  font-family: Inter;
  font-size: 94%;
  font-weight: 700;
  line-height: 18.15px;
  text-align: left;
}
.customize-details{
  height: 410px;
}
@media (max-height: 600px) {
  .customize-details {
      height: 300px;  /* Adjust height further */
  }
}