/* .orders-page-container {
    display: flex;
  } */
  
  .user-profile {
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-left: 20px; /* Space between aside and profile content */
  }
  
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile-details {
    display: table;
    width: 100%;
    margin-top: 20px;
  }
  
  .detail {
    display: table-row; /* Each detail acts as a row in the table */
    margin-bottom: 10px;
  }
  
  .detail label {
    display: table-cell;
    padding: 10px;
    padding-left: 80px;
    font-weight: bold;
    text-align: left;
    width: 200px; /* Adjust this width to align the labels */
  }
  
  .detail div {
    display: table-cell;
    padding: 10px;
    padding-left: 220px;
    background-color: #f4f4f4;
    /* border: 1px solid #ddd; */
    text-align: left;
  }
  